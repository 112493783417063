import React, { useContext, useEffect } from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { CustomerContext } from '../CustomerContext/CustomerContext'
import logo from '../Images/logo_w_text_216.png'

function Header() {
  const { cookies, loggedIn, setLoggedIn, isAdmin, setIsAdmin, isCustomerSupervisor, setIsCustomerSupervisor } = useContext(CustomerContext)

  useEffect(() => {
    if (cookies.get('customer') == null) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
    if (cookies.get('isAdmin') == null) {
      setIsAdmin(cookies.get('isAdmin'));
    } else {
      setIsAdmin(cookies.get('isAdmin'));
    }
    if (cookies.get('isCustomerSupervisor') == null) {
      setIsCustomerSupervisor(cookies.get('isCustomerSupervisor'));
    } else {
      setIsCustomerSupervisor(cookies.get('isCustomerSupervisor'));
    }

    // eslint-disable-next-line
  }, [loggedIn])

  const Logout = () => {
    cookies.remove('customer')
    cookies.remove('isAdmin')
    cookies.remove('isCustomerSupervisor')

    setLoggedIn(false);
    window.location.reload();
  }
  return (
    <Navbar bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="/Dashboard">
          <img
            src={logo}
            height='50px'
            className="d-inline-block align-top"
            alt="Subroclaims"
          />
        </Navbar.Brand>
        <Nav className="nav-links">
          {isAdmin === 'true' ? <Nav.Link href="/userlist">Admin</Nav.Link> : null}

          {loggedIn && <>
            {isCustomerSupervisor === 'true' || isAdmin === 'true' ? <NavDropdown title="Reports" id="basic-nav-dropdown">
              <NavDropdown.Item href="/Dashboard">Dashboard</NavDropdown.Item>
              <NavDropdown.Item href="/ProjectStatus">Project Status</NavDropdown.Item>
              <NavDropdown.Item href="/ProjectSummary">Project Summary</NavDropdown.Item>
              <NavDropdown.Item href="/PaymentPerformance">Payment Performance</NavDropdown.Item>
              {/* <NavDropdown.Item href="/ProjectUIM">Project UIM</NavDropdown.Item> */}
              {/* {isAdmin === 'true' ?<NavDropdown.Item href="/PaymentHistory">Payment History</NavDropdown.Item>:null} */}
              <NavDropdown.Item href="/PaymentHistory">Payment History</NavDropdown.Item>

            </NavDropdown> : <>
              <Nav.Link href="/Dashboard">Dashboard</Nav.Link>
            </>}
          </>
          }
          {loggedIn && <Nav.Link onClick={Logout}>Logout</Nav.Link>}
        </Nav>
      </Container>
    </Navbar>
  )
}

export default Header