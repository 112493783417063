import React, { useContext, useEffect, useState } from 'react'
import { CustomerContext } from '../CustomerContext/CustomerContext'
// import useFetchGet from '../Fetch/useFetchGet';
import OpenAccountsView from './OpenAccountsView'

function OpenAccounts() {
    const [gridApi, setGridApi] = useState();
    const [updateData, satUpdateData] = useState();
    const [filterData, setFilterData] = useState(null);
    const [openDetail, setOpenDetail] = useState(false);
    const [debtorID, setDebtorID] = useState();

    const { cookies } = useContext(CustomerContext)
    const { REACT_APP_API_URL } = process.env;
    // let { data: openAccounts } = useState(`${REACT_APP_API_URL}/GetOpenAccounts?Token=${cookies.get('customer').Token}&showClosed=false`)
    const [isLoading, setIsLoading] = useState(false);
    let { data: openAccounts } = useState()
    const [defaultColDef] = useState({
        resizable: true,
        sortable: true,
        filter: true
    })
    const dateFilterComparator = (filterLocalDateAtMidnight, cellValue)=> {
        // Convert the filter date to a comparable value
        const filterDate = new Date(filterLocalDateAtMidnight);
        const filterValue = filterDate.getTime();

        // Convert the cell value to a comparable value
        const cellDate = new Date(cellValue);
        const cellVal = cellDate.getTime();

        // Compare the cell value with the filter value
        if (cellVal > filterValue) {
            // The cell value is greater than the filter value
            return 1;
        } else if (cellVal < filterValue) {
            // The cell value is less than the filter value
            return -1;
        } else {
            // The cell value is equal to the filter value
            return 0;
        }
    }
    const [columnDefs] = useState([
        // {headerName:'Client Name',field:'clientName'},
        { field: 'clientName', width: 130 },
        { field: 'debtorId', headerName: 'SC ID', width: 90, filter: "agNumberColumnFilter" },
        // { field: 'clientId' },
        { field: 'acctStatus', width: 90 },
        { field: 'projectId', width: 130 },
        // a claim number is a string and can contain alpha numerics.
        { field: 'claimNumber', width: 120 },
        { field: 'debtorStatus', headerName: 'Status Code', width: 120 },
        { field: 'adjuster', headerName: 'Adjuster', width: 130 },
        {
            field: 'listDate', valueFormatter: (param) => formattedDate(param), width: 110, filter: 'agDateColumnFilter',
            filterParams: { comparator: dateFilterComparator }
        },
        {
            field: 'age', width: 80, filter: "agNumberColumnFilter"
        },
        { field: 'statusCode', headerName: 'Status Desc', width: 130 },
        { field: 'statusDate', valueFormatter: (param) => formattedDate(param), width: 110, filter: 'agDateColumnFilter',
        filterParams: { comparator: dateFilterComparator } },
        { field: 'lastPtmDt', valueFormatter: (param) => formattedDate(param), headerName: 'Last Payment Date', width: 100, filter: 'agDateColumnFilter',
        filterParams: { comparator: dateFilterComparator } },
        {
            field: 'lastPtmAmt', headerName: 'Last Payment Amount', width: 100, filter: "agNumberColumnFilter"
        },
    ])
    const HandleChangeData = (val) => {
        satUpdateData(val);
        if (val === '') {
            setFilterData(null);
        }
    }

    const [openUser, setOpenUser] = useState(false);
    useEffect(() => {
        getAllData();
    // eslint-disable-next-line
    }, [setOpenUser, openUser]);

    const getAllData = () => {
        setIsLoading(true)
        fetch(`${REACT_APP_API_URL}/GetOpenAccounts?Token=${cookies.get('customer').Token}&showClosed=${openUser}`).then((res) => res.json())
            .then((json) => {
                setFilterData(json);
                setIsLoading(false)
            })
    }
    const formattedDate = (params) => {
        if (params.value) {
            var date = new Date(params.value);
            return date.toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
            }).split('/').join('-');
        }
    }
    const HandleSubmit = (event, e) => {
        e.preventDefault();
        if (event !== '' && event !== undefined && event.search !== undefined && event.search !== null && event.search !== '' && updateData !== '') {
            setIsLoading(true)
            fetch(`${REACT_APP_API_URL}/SearchAccount?Token=${cookies.get('customer').Token}&search=${event.search}`).then((res) => res.json())
                .then((json) => {
                    setFilterData(json);
                    setIsLoading(false)
                })
        } else {
            // setFilterData(null);
            getAllData();
        }

    }

    // const transformDate = (params) => {
    //     if (params.value) {
    //         return params.value.split('T')[0]
    //     }
    // }
    
    const onGridReady = (params) => {
        setGridApi(params.api)
        params.columnApi.autoSizeAllColumns();
    }

    const onBtnExport = () => {
        gridApi.exportDataAsCsv();
    }

    const onCellClicked = (params) => {
        setDebtorID(params.data.debtorId)
        setOpenDetail(true)
    }
    return (
        <OpenAccountsView
            openAccounts={filterData != null ? filterData : openAccounts}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            onBtnExport={onBtnExport}
            HandleSubmit={HandleSubmit}
            onCellClicked={onCellClicked}
            openDetail={openDetail}
            setOpenDetail={setOpenDetail}
            debtorID={debtorID}
            HandleChangeData={HandleChangeData}
            setOpenUser={setOpenUser}
            openUser={openUser}
            isLoading={isLoading}
        ></OpenAccountsView>
    )
}

export default OpenAccounts