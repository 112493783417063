import React from 'react'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function Financial({ financial }) {
    return (
        <>
            {financial && <Card className='padding'>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Label>Deductible Amount</Form.Label>
                        <Form.Control readOnly defaultValue={financial.deductibleAmount ? financial.deductibleAmount : ''} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Out of Pocket</Form.Label>
                        <Form.Control readOnly defaultValue={financial.outofPocket ? financial.outofPocket : ''} />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Label>Coll</Form.Label>
                        <Form.Control readOnly defaultValue={financial.coll ? financial.coll : ''} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Umbi</Form.Label>
                        <Form.Control readOnly defaultValue={financial.umbi ? financial.umbi : ''} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Tow</Form.Label>
                        <Form.Control readOnly defaultValue={financial.tow ? financial.tow : ''} />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Label>Sal</Form.Label>
                        <Form.Control readOnly defaultValue={financial.sal ? financial.sal : ''} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Rent</Form.Label>
                        <Form.Control readOnly defaultValue={financial.rent ? financial.rent : ''} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Supplement</Form.Label>
                        <Form.Control readOnly defaultValue={financial.supplement ? financial.supplement : ''} />
                    </Form.Group>
                </Row>
                {/* <Row>
                <Form.Group as={Col}>
                    <Form.Label>Adjustments Description</Form.Label>
                    <Form.Control as={'textarea'} defaultValue={financial.adjustmentsDesc ? financial.adjustmentsDesc : ''} />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Adjuster Notes</Form.Label>
                    <Form.Control as={'textarea'} defaultValue={financial.adjusterNotes ? financial.adjusterNotes : ''} />
                </Form.Group>
            </Row> */}
                <Row>
                    <Form.Group as={Col}>
                        <Form.Label>Principal/Balance</Form.Label>
                        <Form.Control readOnly defaultValue={financial.principle ? financial.principle : ''} />
                    </Form.Group>
                </Row>
            </Card>}
        </>
    )
}

export default Financial