import React from 'react'
import { Alert, Modal, Form, Button } from 'react-bootstrap'

function PasswordReset({ error, setNewPassword, setConfirmPassword, setPin, HandleSubmit, setError, show, setShow, loading, confirmPassword, newPassword, validated }) {
  return (
    <Modal show={show} onHide={setShow}>
      <Modal.Header closeButton><h3>Reset Password</h3></Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit} validated={validated} noValidate>
          <Form.Group>
            <Form.Label>Password<span>*</span></Form.Label>
            <Form.Control onChange={(e) => setError(null)} onBlur={(e) => setNewPassword(e.target.value)} type="password" placeholder="Password"  required></Form.Control>
            <Form.Control.Feedback type="invalid">
              Please provide a Password.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Confirm Password<span>*</span></Form.Label>
            <Form.Control onChange={(e) => setError(null)} onBlur={(e) => setConfirmPassword(e.target.value)} type="password" placeholder="Confirm Password"  required></Form.Control>
            <Form.Control.Feedback type="invalid">
              Please provide a Confirm Password.
            </Form.Control.Feedback>
            {confirmPassword !== newPassword && <p className='text-danger'>Password doesn't match</p>}
          </Form.Group>
          <Form.Group>
            <Form.Label>Pin Sent to your Email<span>*</span></Form.Label>
            <Form.Control onChange={(e) => setError(null)} onBlur={(e) => setPin(e.target.value)} type="text" placeholder="Pin Sent to your Email" required></Form.Control>
            <Form.Control.Feedback type="invalid">
              Please provide a Pin Sent to your Email.
            </Form.Control.Feedback>
          </Form.Group>
          <Button disabled={loading} type="submit" className="margin-top">{loading ? 'Submitting...' : 'Submit'}</Button>
          {error &&
            <Alert variant='warning'>{error}</Alert>
          }
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default PasswordReset