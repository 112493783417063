import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
 
function RegisteredOwner({ accountDetails }) {
  const [firstName, setFirstName] = useState(accountDetails.claimantRONameFirst?accountDetails.claimantRONameFirst:'');
  const [lastName, setLastName] = useState(accountDetails.claimantRONameLast?accountDetails.claimantRONameLast:'');
  const [address, setAddress] = useState(accountDetails.roAddress?accountDetails.roAddress:'');
  const [city, setCity] = useState(accountDetails.roCity?accountDetails.roCity:'');
  const [state, setState] = useState(accountDetails.roState?accountDetails.roState:'');
  const [zip, setZip] = useState(accountDetails.rozip?accountDetails.rozip:'');
  const [phone, setPhone] = useState(accountDetails.rOtel1?accountDetails.rOtel1:'');
  const [phoneTwo, setPhoneTwo] = useState(accountDetails.rOtel2?accountDetails.rOtel2:'');
  const [SSN, setSSN] = useState(accountDetails.rossn?accountDetails.rossn:'');
  const [DOB, setDOB] = useState(accountDetails.roDateOfBirth?accountDetails.roDateOfBirth.split('T')[0]:'');

  return (
    <Card className='padding'>
        <Row>
            <Form.Group as={Col}>
                <Form.Label>First Name</Form.Label>
                <Form.Control readOnly value={firstName} onChange={(e)=>setFirstName(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label>Last Name</Form.Label>
                <Form.Control readOnly value={lastName} onChange={(e)=>setLastName(e.target.value)} />
            </Form.Group>
        </Row>
        <Row>
            <Form.Group as={Col}>
                <Form.Label>Phone</Form.Label>
                <Form.Control readOnly value={phone} onChange={(e)=>setPhone(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label>Alt Phone</Form.Label>
                <Form.Control readOnly value={phoneTwo} onChange={(e)=>setPhoneTwo(e.target.value)} />
            </Form.Group>
        </Row>
        <Row>
            <Form.Group as={Col}>
                <Form.Label>Address</Form.Label>
                <Form.Control readOnly value={address} onChange={(e)=>setAddress(e.target.value)} />
            </Form.Group>
        </Row>
        <Row>
            <Form.Group as={Col}>
                <Form.Label>City</Form.Label>
                <Form.Control readOnly value={city} onChange={(e)=>setCity(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label>State</Form.Label>
                <Form.Control readOnly value={state} onChange={(e)=>setState(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label>Zip</Form.Label>
                <Form.Control readOnly value={zip} onChange={(e)=>setZip(e.target.value)} />
            </Form.Group>
        </Row>
        <Row>
            <Form.Group as={Col}>
                <Form.Label>SSN</Form.Label>
                <Form.Control readOnly value={SSN} onChange={(e)=>setSSN(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col}>
                <Form.Label>DOB</Form.Label>
                <Form.Control readOnly value={DOB} onChange={(e)=>setDOB(e.target.value)} />
            </Form.Group>
        </Row>
    </Card>
  )
}

export default RegisteredOwner