import React from 'react';

const LoadingOverlay = () => {
  return (
    <div className="ag-overlay-loading-center">
      <i className="fas fa-spinner fa-spin"></i> Loading...
    </div>
  );
};

export default LoadingOverlay;
