import React, { useContext, useEffect, useState } from 'react'
import { CustomerContext } from '../CustomerContext/CustomerContext';
import GetFinancials from './GetFinancials'

function GetFinancialsLogic() {
    const [rowData, setRowData] = useState();
    const [gridApi, setGridApi] = useState();
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState();
    const [defaultColDef] = useState({
        resizable: true,
        sortable: true,
        filter: true
    })
    const dollarUSLocale = Intl.NumberFormat('en-US', {
        style: "currency",
        currency: "USD",
    });
    const [columnDefs] = useState([
        { headerName: 'Client Name', field: 'clientName', width:125 },
        // {headerName:'Client ID',field:'clientID'},
        {
            headerName: 'List Year', field: 'listYear',
            width: 100, filter:"agNumberColumnFilter"
        },
        {
            headerName: 'List Month', field: 'listMonth',

            width: 105, filter:"agNumberColumnFilter"
        },
        { headerName: 'Project ID', field: 'projectID', width:115 },
        {
            headerName: 'Total Files', field: 'totalFiles1',

            width: 105, filter:"agNumberColumnFilter"
        },
        {
            headerName: 'Principle', field: 'principle1', valueFormatter: (data) => dollarUSLocale.format(data.value),
            width: 120, filter:"agNumberColumnFilter"
        },
        {
            headerName: 'Payments', field: 'payments1', valueFormatter: (data) => dollarUSLocale.format(data.value),
            width: 120, filter:"agNumberColumnFilter"
        },
        {
            headerName: 'Agency Fee', field: 'agencyFee1', valueFormatter: (data) => dollarUSLocale.format(data.value),
            width: 120, filter:"agNumberColumnFilter"
        }
    ])
    const { cookies } = useContext(CustomerContext)
    const { REACT_APP_API_URL } = process.env;

    useEffect(() => {
        if (rowData && rowData.length > 0 && rowData[0] !== null) {
            setPinnedBottomRowData([{
                totalFiles1: `${rowData.reduce((last, current) => last + current.totalFiles1, 0).toFixed(2)}`,
                principle1: `${rowData.reduce((last, current) => last + current.principle1, 0).toFixed(2)}`,
                payments1: `${rowData.reduce((last, current) => last + current.payments1, 0).toFixed(2)}`,
                agencyFee1: `${rowData.reduce((last, current) => last + current.agencyFee1, 0).toFixed(2)}`
            }])
        }
        // eslint-disable-next-line
    }, [rowData])

    useEffect(() => {
        const GetClientFinancials = (token) => {
            fetch(`${REACT_APP_API_URL}/GetClientFinancials?token=${token}`)
                .then(res => {
                    if (res.status === 200) {
                        return res.json()
                    }
                })
                .then(data => {
                    setRowData(data)
                })
        }
        if (!rowData) {
            GetClientFinancials(cookies.get('customer').Token)
        }
        // eslint-disable-next-line
    }, [rowData])

    const onBtnExport = () => {
        gridApi.exportDataAsCsv();
    }

    const onComponentStateChanged = (params) => {
        setGridApi(params.api)
    }

    const onGridReady = (params) => {
        params.api.sizeColumnsToFit()
    }

    const onFilterChanged = (e) => {
        let data = [];
        gridApi.forEachNodeAfterFilter(node => {
            data.push(node.data)
        })
        setPinnedBottomRowData([{
            totalFiles1: `${data.reduce((last, current) => last + current.totalFiles1, 0).toFixed(2)}`,
            principle1: `${data.reduce((last, current) => last + current.principle1, 0).toFixed(2)}`,
            payments1: `${data.reduce((last, current) => last + current.payments1, 0).toFixed(2)}`,
            agencyFee1: `${data.reduce((last, current) => last + current.agencyFee1, 0).toFixed(2)}`
        }])
    }

    return (
        <GetFinancials
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onComponentStateChanged={onComponentStateChanged}
            onBtnExport={onBtnExport}
            onGridReady={onGridReady}
            pinnedBottomRowData={pinnedBottomRowData}
            onFilterChanged={onFilterChanged}
        ></GetFinancials>
    )
}

export default GetFinancialsLogic