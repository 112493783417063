import React, { useState } from 'react'
import ForgotPassword from './ForgotPassword';

function ForgotPasswordLogic({ show, setShow, setMessage }) {
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [passwordResetIsOpen, setPasswordResetIsOpen] = useState(false);
    const [resetMessage, setResetMessage] = useState();
    const { REACT_APP_API_URL } = process.env;

    const HandleSubmit = (e) => {
        e.preventDefault()
        if (username === '') {
            setError('Username is required');
            return;
        } else {
            setLoading(true);
            fetch(`${REACT_APP_API_URL}/RequestReset?username=${username}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            })
                .then(res => {
                    if (res.status === 200) {
                        // setMessage('Reset successful')
                        setLoading(false)
                        setPasswordResetIsOpen(true)
                        // setTimeout(() => {
                        //     setShow(false)

                        // }, 100);
                    } else {
                        setError('Issue resetting password, please try again');
                    }
                    setLoading(false)
                })
        }
    }

    return (
        <ForgotPassword
            setUsername={setUsername}
            HandleSubmit={HandleSubmit}
            error={error}
            setError={setError}
            show={show}
            setShow={setShow}
            loading={loading}
            username={username}
            setResetMessage={setResetMessage}
            passwordResetIsOpen={passwordResetIsOpen}
            resetMessage={resetMessage}
            setPasswordResetIsOpen={setPasswordResetIsOpen}
        ></ForgotPassword>
    )
}

export default ForgotPasswordLogic;