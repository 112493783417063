import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function Claimant({ accountDetails }) {
    const [firstName, setFirstName] = useState(accountDetails.claimantName1First ? accountDetails.claimantName1First : '');
    const [lastName, setLastName] = useState(accountDetails.claimantName1Last ? accountDetails.claimantName1Last : '');
    const [address, setAddress] = useState(accountDetails.address ? accountDetails.address : '');
    const [city, setCity] = useState(accountDetails.city ? accountDetails.city : '');
    const [state, setState] = useState(accountDetails.stateProvCd ? accountDetails.stateProvCd : '');
    const [zip, setZip] = useState(accountDetails.postalCode ? accountDetails.postalCode : '');
    const [phone, setPhone] = useState(accountDetails.telephone ? accountDetails.telephone : '');
    const [phoneTwo, setPhoneTwo] = useState(accountDetails.telephone2 ? accountDetails.telephone2 : '');
    const [SSN, setSSN] = useState(accountDetails.ssn?accountDetails.ssn:'');
    const [DOB, setDOB] = useState(accountDetails.dateOfBirth ? accountDetails.dateOfBirth.split('T')[0] : '');
    const [licenseOrPermit, setLicenseOrPermit] = useState(accountDetails.driversLicense ? accountDetails.driversLicense : '');

    return (
        <Card className='padding'>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control readOnly value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control readOnly value={lastName} onChange={(e) => setLastName(e.target.value)} />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control readOnly value={phone} onChange={(e) => setPhone(e.target.value)} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Alt Phone</Form.Label>
                    <Form.Control readOnly value={phoneTwo} onChange={(e) => setPhoneTwo(e.target.value)} />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Address</Form.Label>
                    <Form.Control readOnly value={address} onChange={(e) => setAddress(e.target.value)} />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>City</Form.Label>
                    <Form.Control readOnly value={city} onChange={(e) => setCity(e.target.value)} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>State</Form.Label>
                    <Form.Control readOnly value={state} onChange={(e) => setState(e.target.value)} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Zip</Form.Label>
                    <Form.Control readOnly value={zip} onChange={(e) => setZip(e.target.value)} />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>SSN</Form.Label>
                    <Form.Control readOnly value={SSN} onChange={(e) => setSSN(e.target.value)} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>DOB</Form.Label>
                    <Form.Control readOnly value={DOB} onChange={(e) => setDOB(e.target.value)} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>License/Permit#</Form.Label>
                    <Form.Control readOnly value={licenseOrPermit} onChange={(e) => setLicenseOrPermit(e.target.value)} />
                </Form.Group>
            </Row>
        </Card>
    )
}

export default Claimant