import React from 'react'
import {Button} from 'react-bootstrap'
import {AgGridReact} from 'ag-grid-react'

function GetPaymentPerformanceDetail({ pinnedBottomRowData, onFilterChanged, onBtnExport, rowData, columnDefs, defaultColDef, onComponentStateChanged }) {
  return (
    <>
    <div className='export-button-alt'>
    <Button href='/PaymentPerformance'>Go Back</Button>
        <Button variant='secondary' onClick={onBtnExport}>Download CSV</Button>
    </div>
    <div className="ag-theme-alpine grid" >
        <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onComponentStateChanged={onComponentStateChanged}
            pinnedBottomRowData={pinnedBottomRowData}
            onFilterChanged={onFilterChanged}
        />
    </div>
</>
  )
}

export default GetPaymentPerformanceDetail