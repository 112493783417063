import React, { useState } from "react";
import { Alert, Button, Card, Form, Spinner } from 'react-bootstrap'
import PasswordResetLogic from "../PasswordReset/PasswordResetLogic";
import ForgotPasswordLogic from "../ForgotPassword/ForgotPasswordLogic";
import { useNavigate } from "react-router-dom";

const Login = ({ 
    HandleSubmit,
    validated,
    HandleChange,
    passwordResetIsOpen,
    setPasswordResetIsOpen,
    postObj,
    error,
    resetMessage,
    setResetMessage,
    loading
 }) => {
    const navigate = useNavigate();

    const [forgotPasswordIsOpen, setForgotPasswordIsOpen] = useState(false);
    const goToForgotPassword = () => {
        navigate('/forgotPassword', { replace: false })

        // setForgotPasswordIsOpen(true);
    }
    return (
        <div className="login-card-wrapper">
            {passwordResetIsOpen && <PasswordResetLogic setMessage={setResetMessage} email={postObj.username} show={passwordResetIsOpen} setShow={setPasswordResetIsOpen} />}
            {forgotPasswordIsOpen && <ForgotPasswordLogic setMessage={setResetMessage} show={forgotPasswordIsOpen} setShow={setForgotPasswordIsOpen} />}
            <Card className='login-card'>
                <Card.Header><h3>Login</h3></Card.Header>
                <Card.Body>
                    <Form onSubmit={HandleSubmit} noValidate validated={validated}>
                        <Form.Group>
                            <Form.Label>Welcome to the new client portal for SubroClaims. If this is your first time, you can reset your password by clicking Forgot Password. CRSWeb no longer accessible.</Form.Label>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Username<span>*</span></Form.Label>
                            <Form.Control type="text" onChange={(e) => HandleChange(e, 'username')} placeholder="Username" required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a Username.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Password<span>*</span></Form.Label>
                            <Form.Control onChange={(e) => HandleChange(e, 'password')} type="password" placeholder="Password" required />

                            <Form.Control.Feedback type="invalid">
                                Please provide a Password.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button type="submit" className="margin-top">
                            {loading?
                            <Spinner animation='border' size="sm" />
                            :'Submit'}
                        </Button>
                        <Button variant="link" className="float-right" onClick={() => goToForgotPassword()}>Forgot password?</Button>
                        {error && <Alert className="margin-top" variant="warning">{error}</Alert>}
                        {resetMessage && <Alert className="margin-top">{resetMessage}</Alert>}
                        <Alert className="margin-top">
                            In accordance with enhanced security requirements, 2 factor authentication will be enforced. 
                            After entering your username and password, you will receive an email containing a pin. 
                            You will be prompted to enter that pin in order to continue.
                        </Alert>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    )
}

export default Login;