import React, { useContext, useState, useEffect } from 'react'
import GetPaymentPerformanceDetail from './GetPaymentPerformanceDetail'
import useFetchGet from '../../Fetch/useFetchGet'
import { CustomerContext } from '../../CustomerContext/CustomerContext'
import { useLocation } from 'react-router-dom'

function GetPaymentPerformanceDetailLogic() {
    const {cookies} = useContext(CustomerContext)
    const {REACT_APP_API_URL} = process.env;
    const [gridApi, setGridApi] = useState();
    const { state } = useLocation();
    const { month, year } = state;
    const {data} = useFetchGet(`${REACT_APP_API_URL}/GetPaymentPerformanceDetail?token=${cookies.get('customer').Token}&month=${month}&year=${year}`,cookies)
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState();
    const [defaultColDef] = useState({
        resizable: true,
        sortable: true,
        filter: true
    })
    const [columnDefs] = useState([
        {headerName:'Client ID',field:'clientId'},
        {headerName:'Year',field:'year'},
        {headerName:'Month',field:'month'},
        {headerName:'Project',field:'project'},
        {headerName:'Principle',field:'prinbal'},
        {headerName:'Month 1',field:'month1'},
        {headerName:'Month 2',field:'month2'},
        {headerName:'Month 3',field:'month3'},
        {headerName:'Month 4',field:'month4'},
        {headerName:'Month 5',field:'month5'},
        {headerName:'Month 6',field:'month6'},
        {headerName:'Month 7',field:'month7'},
        {headerName:'Month 8',field:'month8'},
        {headerName:'Month 9',field:'month9'},
        {headerName:'Month 10',field:'month10'},
        {headerName:'Month 11',field:'month11'},
        {headerName:'Month 12',field:'month12'},
        {headerName:'Past Month 12',field:'pastMonth12'},
    ])

    useEffect(()=>{
        if(data){
            setPinnedBottomRowData([{
                prinbal: `${data.reduce((last,current)=>last+current.prinbal,0).toFixed(2)}`,
                month1: `${data.reduce((last,current)=>last+current.month1,0).toFixed(2)}`,
                month2: `${data.reduce((last,current)=>last+current.month2,0).toFixed(2)}`,
                month3: `${data.reduce((last,current)=>last+current.month3,0).toFixed(2)}`,
                month4: `${data.reduce((last,current)=>last+current.month4,0).toFixed(2)}`,
                month5: `${data.reduce((last,current)=>last+current.month5,0).toFixed(2)}`,
                month6: `${data.reduce((last,current)=>last+current.month6,0).toFixed(2)}`,
                month7: `${data.reduce((last,current)=>last+current.month7,0).toFixed(2)}`,
                month8: `${data.reduce((last,current)=>last+current.month8,0).toFixed(2)}`,
                month9: `${data.reduce((last,current)=>last+current.month9,0).toFixed(2)}`,
                month10: `${data.reduce((last,current)=>last+current.month10,0).toFixed(2)}`,
                month11: `${data.reduce((last,current)=>last+current.month11,0).toFixed(2)}`,
                month12: `${data.reduce((last,current)=>last+current.month12,0).toFixed(2)}`,
                pastMonth12: `${data.reduce((last,current)=>last+current.pastMonth12,0).toFixed(2)}`,
            }])
        }
    },[data])

    const onBtnExport = () => { 
        gridApi.exportDataAsCsv();
     }

    const onComponentStateChanged = (params) => { 
        setGridApi(params.api)
     }

  return (
    <GetPaymentPerformanceDetail
        rowData={data}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        onComponentStateChanged={onComponentStateChanged}
        onBtnExport={onBtnExport}
        pinnedBottomRowData={pinnedBottomRowData}
    ></GetPaymentPerformanceDetail>
  )
}

export default GetPaymentPerformanceDetailLogic