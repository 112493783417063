import React, { useContext, useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { CustomerContext } from '../../../CustomerContext/CustomerContext'

function Comments({ activities, debtorID }) {
    const [loader, setLoader] = useState(false);
    const { cookies } = useContext(CustomerContext)
    const [activityList, setActivityList] = useState(activities);
    const [searchString, setSearchString] = useState();
    const [notes, setNotes] = useState('');
    const { REACT_APP_API_URL } = process.env;

    useEffect(()=>{
        if(searchString&&searchString!==''){
            let newListToDisplay = []
            activities.forEach(activity=>{
                if(activity.comments.toUpperCase().includes(searchString.toUpperCase())){
                    newListToDisplay.push(activity)
                }
            })
            setActivityList(newListToDisplay)
        }else if(searchString===''){
            setActivityList(activities)
        }
    // eslint-disable-next-line
    },[searchString])


    const handleSubmit = (event) => {
        event.preventDefault();

        setLoader(true);
        if (notes === '') {
            setLoader(false);

            event.stopPropagation();
        } else {
            // return;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({})
            };
            let url = `${REACT_APP_API_URL}/PostClaimNotes?Token=${cookies.get('customer').Token}&debtorid=${debtorID}&note=${encodeURIComponent(notes)}`;
            fetch(url, requestOptions).then((res) => res.json())
                .then((json) => {
                    setNotes('');
                    activities.unshift({
                        "activityID": json.activityID,
                        "collector": json.collector,
                        "date": json.date,
                        "comments": json.comments
                    })
                    setLoader(false);

                })
        }
    }

    return (
        <div className='commntsWrapper' >
            <Form.Control placeholder='Search...' onChange={(e)=>setSearchString(e.target.value)}></Form.Control>

            <div className='commntsText padding card'>
                {activityList.map(item => (<Row key={item.activityID}>
                    <div>
                        <span style={{ marginRight: '5px', color: '#51adf4' }}>{item.collector}</span><span style={{ marginLeft: '5px', color: '#51adf4' }}>{item.date.split('T')[0]}</span>
                        <p>{item.comments}</p>
                    </div>

                </Row>))}
            </div>

            <Form as={Col} className='margin-top'>
                <Row>
                    <Col sm={10}>
                        <Form.Control placeholder='Add Note...' value={notes} onChange={(e) => setNotes(e.target.value)} />
                    </Col>
                    <Col sm={2}>
                        <Button className='full-width' onClick={handleSubmit} disabled={loader} >{loader ? 'Loading...' : 'Submit'}</Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default Comments
