import React from 'react'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
 
function Insured({ accountDetails }) {
  return (
    <Card className='padding'>
    <Row>
        <Form.Group as={Col}>
            <Form.Label>First Name</Form.Label>
            <Form.Control readOnly defaultValue={accountDetails.insuredFirstName?accountDetails.insuredFirstName:''}  />
        </Form.Group>
        <Form.Group as={Col}>
            <Form.Label>Last Name</Form.Label>
            <Form.Control readOnly defaultValue={accountDetails.insuredLastName?accountDetails.insuredLastName:''}  />
        </Form.Group>
    </Row>
    <Row>
      <Form.Group as={Col}>
            <Form.Label>Insured Vehicle</Form.Label>
            <Form.Control readOnly defaultValue={accountDetails.insuredVehicle?accountDetails.insuredVehicle:''}  />
        </Form.Group>
    </Row>
</Card>
  )
}

export default Insured