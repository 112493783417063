import React from 'react'
import PasswordResetLogic from '../PasswordReset/PasswordResetLogic'
import { Button, Card, Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

function ForgotPassword({ error, setUsername, username, HandleSubmit, setError, loading, passwordResetIsOpen, setResetMessage, setPasswordResetIsOpen }) {
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate('/', { replace: true })
  }
  return (
    <>
      <div className="login-card-wrapper">
        {passwordResetIsOpen && <PasswordResetLogic setMessage={setResetMessage} email={username} show={passwordResetIsOpen} setShow={setPasswordResetIsOpen} />}
        <Card className='login-card'>
          <Card.Header><h3>Forgot Password</h3></Card.Header>
          <Card.Body>
            <Form onSubmit={HandleSubmit}>
              <Form.Label>Enter your username, which is typically your email. Once you click submit you will receive a new email from notice@subroclaims.com containing a PIN number. Check your spam folder if you don't see it. That PIN will be needed to set your password on the next page.</Form.Label>
              <Form.Label>Username<span>*</span></Form.Label>
              <Form.Control onChange={(e) => setError(null)} onBlur={(e) => setUsername(e.target.value)} type="text" placeholder="Username"></Form.Control>
              {error &&
                <p className='text-danger'>{error}</p>
              }
              <Button disabled={loading} type="submit" className="margin-top">{loading ? 'Submitting...' : 'Submit'}</Button>

              <Button variant="link" className="float-right" onClick={() => goToLogin()}>Login</Button>

            </Form>

          </Card.Body>
        </Card>
      </div>
    </>
  )
}

export default ForgotPassword