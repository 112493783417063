import React, { useContext, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { CustomerContext } from '../../CustomerContext/CustomerContext'
import { AgGridReact } from 'ag-grid-react'
import { useRef } from 'react'

function UserAddEdit({ accountDetails, setShow, onSubmit, SetHeaderData }) {
    const formRef = useRef(null);
    const { cookies } = useContext(CustomerContext)
    const { REACT_APP_API_URL } = process.env;
    const [clientshow, setclientshow] = useState(false);
    const [projetshow, setprojetshow] = useState(false);
    const [hasItems, sethasItems] = useState([]);
    const [hasLimits, sethasLimits] = useState([]);
    const [state, setState] = useState(accountDetails);
    const [isAdmin, setIsAdmin] = useState(accountDetails.isAdmin?accountDetails.isAdmin:false);
    const [isCustomerSupervisor, setIsCustomerSupervisor] = useState(accountDetails.isCustomerSupervisor?accountDetails.isCustomerSupervisor:false);
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [getClients, setgetClients] = useState([]);
    const [projects, setProjects] = useState([]);
    const [loader, setLoader] = useState(false);
    const [projectListloader, setprojectListloader] = useState(false);
    const [gridApi, setGridApi] = useState();

    const [columnDefsProjects] = useState([
        // { headerName: 'User Id', field: 'userId', },

        { headerName: 'Project', field: 'projectId', width: 300 },
        {
            headerName: 'Actions',
            field: 'limitProject',
            cellRendererFramework: function (params) {
                return (
                    <input
                        type="checkbox"
                        checked={params.value}
                        onChange={(event) => {
                            params.setValue(event.target.checked);
                        }}
                    />
                );
            }
        }

    ]);
    const [columnDefsClients] = useState([
        // { headerName: 'User Id', field: 'userId', },

        { headerName: 'Client', field: 'clientname' },

        // { headerName: 'Action', field: 'limitProject', checkboxSelection: true},
        {
            headerName: 'Actions',
            field: 'hasAccess',
            cellRendererFramework: function (params) {
                return (
                    <input
                        type="checkbox"
                        checked={params.value}
                        onChange={(event) => {
                            params.setValue(event.target.checked);
                        }}
                    />
                );
            }
        }

    ]);
    const onBtnExport = () => {
        gridApi.forEachNodeAfterFilter(node => {
            // data.push(node.data);
        })

    };
    const handleCheckChange = e => {
        const { name, checked } = e.target;
        if (name === 'isAdmin'&&checked) {
            setIsCustomerSupervisor(false);
            setIsAdmin(true);
        
        } else if (name === 'isCustomerSupervisor'&&checked){
            setIsAdmin(false);
            setIsCustomerSupervisor(true);
        } else {
            setState(prevState => ({
                ...prevState,
                [name]: checked
            }));
        }
    };

    const handleChange = e => {
        const { name, value } = e.target;
            setState(prevState => ({
                ...prevState,
                [name]: value
            }));
    };
    const [validated, setValidated] = useState(false);

    const [defaultColDef] = useState({
        resizable: true,
        sortable: true,
        filter: true
    })
    const handleSubmit = (event) => {
        event.preventDefault();

        // Access the form element using the ref
        const form = formRef.current;

        // Check the validity of the form
        if (form.checkValidity()) {
            // Form is valid
            setValidated(true);
            let optionVal = {};
            if (typeof state.userId !== 'undefined' && state.userId !== null && state.userId !== '') {
                state.password = password;
                state.password2 = password2;
                state.isAdmin = isAdmin;
                state.isCustomerSupervisor = isCustomerSupervisor;
                delete state.saltValue;
                optionVal = state;
            } else {
                state.lastLogon = "2000-01-01T00:00:00";
                delete state.saltValue;

                optionVal = state;
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(optionVal)
            };
            let url = `${REACT_APP_API_URL}/UpdateOrAddUser?Token=${cookies.get('customer').Token}`;
            fetch(url, requestOptions).then((res) => res.json())
                .then((json) => {
                    onSubmit(json)
                })
            // Perform further actions, such as submitting the form
        } else {
            // Form is invalid
            setValidated(false);

        }
    }
    const handleGetClient = () => {
        SetHeaderData('Client List');
        setLoader(true);

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        let url = `${REACT_APP_API_URL}/GetUserClientsAccess?Token=${cookies.get('customer').Token}&userId=${state.userId}`;
        fetch(url, requestOptions).then((res) => res.json())
            .then((json) => {
                setclientshow(true);
                sethasItems(json);
                setgetClients(json)
                setLoader(false);

            })
    }

    const handleGetProject = () => {
        SetHeaderData('Project List');
        setprojectListloader(true);
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        let url = `${REACT_APP_API_URL}/GetUserProjectLimits?Token=${cookies.get('customer').Token}&userId=${state.userId}`;
        fetch(url, requestOptions).then((res) => res.json())
            .then((json) => {
                setclientshow(true);
                setprojetshow(true);
                setProjects(json);
                sethasLimits(json);
                setprojectListloader(false);

            })
    }
    const handleSubmitGetClient = () => {
        setLoader(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(hasItems)
        };
        let url = `${REACT_APP_API_URL}/PostClientUsers?Token=${cookies.get('customer').Token}`;
        fetch(url, requestOptions).then((res) => {
            SetHeaderData('Edit User');

            setclientshow(false);
            setLoader(false);
        })

    }
    const handleSubmitGetProjects = () => {
        setLoader(true);
        // return
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(hasLimits)
        };
        let url = `${REACT_APP_API_URL}/PostProjectUsers?Token=${cookies.get('customer').Token}`;
        fetch(url, requestOptions).then((res) => {
            setclientshow(false);
            setprojetshow(false);
            SetHeaderData('Edit User');

            setLoader(false);
            setprojectListloader(false)
        })
    }

    const onGridReady = (params) => {
        setGridApi(params.api)
        params.columnApi.autoSizeAllColumns();
    }
    return (
        // <p>test</p>  clientshow={clientshow.toString()}
        <Card className='padding'>

            {!clientshow ? <Form noValidate validated={validated} ref={formRef}>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Label>User Name<span>*</span></Form.Label>
                        <Form.Control placeholder='User Name' value={state.userName} onChange={handleChange} name="userName" required />
                        <Form.Control.Feedback type="invalid">
                            Please provide a User Name.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Label>Surname<span>*</span></Form.Label>
                        <Form.Control value={state.surname} onChange={handleChange} name="surname" placeholder="Surname" required />
                        <Form.Control.Feedback type="invalid">
                            Please provide a Surename.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Given Name<span>*</span></Form.Label>
                        <Form.Control value={state.givenName} onChange={handleChange} name="givenName" placeholder="Given Name" required />
                        <Form.Control.Feedback type="invalid">
                            Please provide a Given name.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                {(state.userId === null || state.userId === undefined) ? <Row>
                    <Form.Group as={Col}>
                        <Form.Label>Password</Form.Label>
                        <Form.Control value={state.password} placeholder="Password" type='password' onChange={handleChange} name="password" />
                        {/* <Form.Control.Feedback type="invalid">
                            Please provide a Password.
                        </Form.Control.Feedback> */}
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control value={state.password2} placeholder='Confirm Password' type='password' onChange={handleChange} name="password2" />
                        {/* <Form.Control.Feedback type="invalid">
                            Password dose not match.
                        </Form.Control.Feedback> */}

                        {state.password2 !== state.password && <Form.Control.Feedback className='text-danger'> password does not match</Form.Control.Feedback>}
                    </Form.Group>
                </Row> :
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label>Change Password</Form.Label>
                            <Form.Control value={password} placeholder='Change Password' type='password' onChange={(e) => setPassword(e.target.value)} name="password" />
                            {/* <Form.Control.Feedback type="invalid">
               Please provide a Password.
           </Form.Control.Feedback> */}
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control value={password2} placeholder='Confirm Password' type='password' onChange={(e) => setPassword2(e.target.value)} name="password2" required={password !== '' ? true : false} />
                            <Form.Control.Feedback type="invalid">
                                Password dose not match.
                            </Form.Control.Feedback>

                            {password2 !== password && <p className='text-warning'> password does not match</p>}
                        </Form.Group>
                    </Row>}
                <Row>
                    <Form.Group as={Col}>
                        <Form.Label>Email<span>*</span></Form.Label>
                        <Form.Control type='email' placeholder='Email' value={state.emailAddr1} onChange={handleChange} name="emailAddr1" required />
                        <Form.Control.Feedback type="invalid">
                            Please provide a Email.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Another Email</Form.Label>
                        <Form.Control type='email' placeholder='Another Email' value={state.emailAddr2} onChange={handleChange} name="emailAddr2" />
                    </Form.Group>
                </Row>

                <Row>
                    <Form.Group as={Col}>
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control type='Number' placeholder='Phone Number' value={state.phoneNumber1} onChange={handleChange} name="phoneNumber1" />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>Another Phone Number</Form.Label>
                        <Form.Control type='Number' placeholder='Another Phone Number' value={state.phoneNumber2} onChange={handleChange} name="phoneNumber2" />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Check
                            type={'checkbox'}
                            label={`Active`}
                            checked={state.active}
                            onChange={handleCheckChange} 
                            name="active"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Check
                            type={'checkbox'}
                            label={'Group Regions'}
                            checked={state.groupRegions}  
                            onChange={handleCheckChange} 
                            name="groupRegions"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Check
                            type={'checkbox'}
                            label={`Customer Supervisor`}
                            checked={isCustomerSupervisor}
                            onChange={handleCheckChange} 
                            name="isCustomerSupervisor"
                        />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Check
                            type={'checkbox'}
                            label={`Is Admin`}
                            checked={isAdmin}
                            onChange={handleCheckChange} 
                            name="isAdmin"
                        />
                    </Form.Group>

                </Row>
                <div className='button-cls'>

                <Button variant='secondary' onClick={handleSubmit}>Submit</Button>
                <Button variant='secondary' style={{marginLeft:10}} onClick={() => setShow(false)}>Close</Button>
                {(state.userId) ? <Button variant='secondary' style={{marginLeft:10}} onClick={handleGetClient} disabled={loader} >{loader ? 'Loading...' : 'Client List'}</Button> : ''}
                {(state.userId) ? <Button variant='secondary' style={{marginLeft:10}} onClick={handleGetProject} disabled={projectListloader} >{projectListloader ? 'Loading...' : 'Project List'}</Button> : ''}
                </div>
            </Form> : clientshow && !projetshow ?
                <div>
                    <div className='contentInfo'>
                        {/* {getClients.map((item, index) => (
                            <div key={`index-${item.clientId}`} className={`grid-view`} style={{ backgroundColor: index % 2 === 0 ? 'lightgray' : '#fff' }}>

                                <div className="row-70">   {item.clientname}</div>
                                <div className="row-30 form-Secondrow">  <input type="checkbox" style={{ marginLeft: '1.5rem' }} id={`checkbox-${index}`} name='hasAccess' onChange={(event) => handlehasAccessChange(event, index)} value={item.hasAccess} checked={item.hasAccess} />
                                    <label htmlFor={`checkbox-${index}`}> HasAccess</label>

                                </div>
                            </div>
                        ))} */}
                        <div className="ag-theme-alpine grid" >
                            <AgGridReact
                                rowData={getClients}
                                columnDefs={columnDefsClients}
                                defaultColDef={defaultColDef}
                                onGridReady={onGridReady}
                                onFilterChanged={onBtnExport}
                            />
                        </div>
                    </div>
                    <div className='button-cls'>
                        <Button variant='secondary' onClick={handleSubmitGetClient} disabled={loader} >{loader ? 'Submitting...' : 'Submit'}</Button>
                        <Button variant='secondary' style={{marginLeft:10}} onClick={() => { setclientshow(false); SetHeaderData('Edit User') }}>Close</Button>
                    </div>
                </div>
                : projetshow && clientshow ? <div>
                    <div className='contentInfo'>
                        {/* {projects.map((item, index) => (
                            <div key={`projectindex-${index}`} className={`grid-view`} style={{ backgroundColor: index % 2 === 0 ? 'lightgray' : '#fff' }}>

                                <div className="row-70">   {item.projectId}</div>
                                <div className="row-30 form-Secondrow">  <input type="checkbox" style={{ marginLeft: '1.5rem' }} id={`checkbox-${index}`} name='limitProject' onChange={(event) => handlehasProjectsChange(event, index)} value={item.limitProject} checked={item.limitProject} />
                                    <label htmlFor={`checkbox-${index}`}> Projects</label>

                                </div>
                            </div>
                        ))} */}
                        <div className="ag-theme-alpine grid" >
                            <AgGridReact
                                rowData={projects}
                                columnDefs={columnDefsProjects}
                                defaultColDef={defaultColDef}
                                onGridReady={onGridReady}
                                onFilterChanged={onBtnExport}
                            />
                        </div>
                    </div>
                    <Button variant='secondary' onClick={handleSubmitGetProjects} disabled={loader} >{loader ? 'Submitting...' : 'Submit'}</Button>
                    <Button variant='secondary' onClick={() => { setclientshow(false); SetHeaderData('Edit User') }}>Close</Button>
                </div> : ''
            }
            {/* <Modal.Footer> */}
            {/* <Button className='half-width' variant='success' onClick={() => testValue()}>Submit</Button> */}
            {/* </Modal.Footer> */}
        </Card>
    )
}

export default UserAddEdit