import React, { useState } from 'react'
import Cookies from 'universal-cookie';

export const CustomerContext = React.createContext();

export function CustomerProvider({ children }) {
  const cookies = new Cookies();
  const [loggedIn, setLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isCustomerSupervisor, setIsCustomerSupervisor] = useState(false);
  return (
    <CustomerContext.Provider value={
      {
        cookies,
        loggedIn,
        setLoggedIn,
        isAdmin,
        setIsAdmin,
        isCustomerSupervisor,
        setIsCustomerSupervisor
      }
    }>{children}</CustomerContext.Provider>
  )
}
