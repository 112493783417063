import React from 'react'



function PaymentHistory({ paymentHistory }) {
    return (
        <div className='commntsWrapper' >
            <div className='commntsText padding card'>

                <table>
                    <thead>
                        <tr>
                            <th>Payment Date</th>
                            <th>Payment Amount</th>
                            <th> Remit Amount</th>
                            <th> Remit Date</th>
                        </tr>
                    </thead>


                    <tbody>
                        {paymentHistory.map(item => (<tr key={item.debtorID}>
                            <td>{item.paymentDate.split('T')[0]}</td>
                            <td>$ {item.paymentAmount}</td>
                            <td>$ {item.remit_Amount}</td>
                            <td>{typeof item.dateRemitted == 'string' ? item.dateRemitted.split('T')[0] :''}</td>
                        </tr>))}

                    </tbody>

                </table>


            </div>
        </div>
    )
}

export default PaymentHistory
