import React, { useEffect, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import AccountDetailsPopup from './AccountDetailsPopup/AccountDetailsPopup'
import { Form } from 'react-bootstrap'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import LoadingOverlay from './UserDetails/LoadingOverlay'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import NoRowsOverlay from './UserDetails/NoRowsOverlay'
function OpenAccountsView({
    openAccounts,
    defaultColDef,
    columnDefs,
    onGridReady,
    onBtnExport,
    HandleSubmit,
    onCellClicked,
    openDetail,
    setOpenDetail,
    HandleChangeData,
    debtorID,
    setOpenUser,
    openUser,
    isLoading,
}) {
    const gridRef = useRef(null);
    const [postObj, setPostObj] = useState({});
    
    useEffect(() => {
        setTimeout(() => {
            gridRef.current.api.showLoadingOverlay();
        }, 100);

    }, [openUser])

    useEffect(() => {
        clearColumnFilter()
    }, [HandleSubmit])

    const HandleChange = (e, fieldName) => {
        e.preventDefault();
        postObj[fieldName] = e.target.value;
        setPostObj(postObj);
        HandleChangeData(e.target.value);
        clearColumnFilter();
        if (e.target.value === '') {
            clearColumnFilter();
            HandleSubmit('', e);
        }
    }
    const clearColumnFilter = () => {
        const api = gridRef.current.api;
        if (api !== undefined) {
            api.setFilterModel(null);
            api.onFilterChanged();
        }
    };

    return (
        <>
            {openDetail && <AccountDetailsPopup show={openDetail} setShow={setOpenDetail} debtorId={debtorID} />}
            <Row>
                <Col className='page-header-wrapper'>
                    <h3 className='page-header'>Account Status</h3>
                </Col>
                <Col className='page-header-wrapper'>
                    <BootstrapSwitchButton checked={openUser} onstyle="secondary" size="md" width={300} onlabel='Switch to Open Claims'
                        offlabel='Switch to Closed Last 30 days'
                        onChange={(checked) => {
                            setOpenUser(checked)
                        }} />
                </Col>
                <Col className='page-header-wrapper'>
                    <Form className="d-flex">
                        <Form.Control
                            onChange={(e) => HandleChange(e, 'search')}
                            // onKeyDown={handleKeyPress}
                            type="search"
                            placeholder="Search"
                            className="me-2"
                            aria-label="Search"
                        />
                        <Button type='submit' disabled={postObj === undefined || postObj === '' || postObj.search === undefined || postObj.search === ''} variant="outline-success" onClick={(e) => HandleSubmit(postObj,e)}>Search</Button>
                    </Form>
                </Col>
                <Col className='export-button'>
                    <Button variant='secondary' onClick={onBtnExport}>Download CSV</Button>
                </Col>
            </Row>
            <div className="ag-theme-alpine grid" >
                <AgGridReact
                    ref={gridRef}
                    rowData={openAccounts}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    onCellClicked={onCellClicked}
                    loadingOverlayComponent={LoadingOverlay}
                    loadingOverlayParams={{ isLoading }}
                    noRowsOverlayComponent={NoRowsOverlay}
                    noRowsOverlayParams={{ isLoading, noRowsMessage: 'No Rows To Show' }}
                // overlayLoadingTemplate={
                //     '<span class="ag-overlay-loading-center">Loading...</span>'
                // }
                />
            </div>
        </>
    )
}

export default OpenAccountsView
