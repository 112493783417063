import React from 'react';

const NoRowsOverlay = ({ isLoading }) => {
  if (isLoading) {
    return null; // Don't render the overlay if data is still loading
  }

  return (
    <div className="ag-overlay-no-rows-center">
      No Rows To Show
    </div>
  );
};

export default NoRowsOverlay;
