import React from 'react'
// import { CustomerContext } from '../../CustomerContext/CustomerContext';
// import useFetchGet from '../../Fetch/useFetchGet';
import UsersDetailsPopupView from './UsersDetailsPopupView';

function UsersDetailsPopup({ show, setShow, data, onSubmit }) {
  return (
    <UsersDetailsPopupView
      show={show}
      setShow={setShow}
      accountDetails={data}
      onSubmit={onSubmit}
    ></UsersDetailsPopupView>
  )
}

export default UsersDetailsPopup