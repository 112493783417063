import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import UserAddEdit from "./UserAddEdit"

function UsersDetailsPopupView({ show, setShow, accountDetails, onSubmit }) {
    const [modalHeader, setModalHeader] = useState(accountDetails && accountDetails.userId ? 'Edit User' : 'Add User');
    let SetHeaderData =(data)=>{
        setModalHeader(data);
    }
    return (
        <Modal show={show} onHide={setShow}>
            <Modal.Header closeButton>{modalHeader}</Modal.Header>
            <Modal.Body>
                {accountDetails ?
                    <UserAddEdit accountDetails={accountDetails} setShow={setShow} onSubmit={onSubmit} SetHeaderData={SetHeaderData} />
                    :
                    <Spinner animation='border' />
                }
            </Modal.Body>
        </Modal>
    )
}

export default UsersDetailsPopupView