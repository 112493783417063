import React, { useState, useContext } from 'react'
import { CustomerContext } from '../CustomerContext/CustomerContext'
import { useNavigate, useLocation } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'

function TwoFactorAuth() {
  const navigate = useNavigate();
  const location = useLocation();
  const [postObj, setPostObj] = useState({
    username: location.state.username
  });
  const { REACT_APP_API_URL } = process.env;
  const [error, setError] = useState();
  const { cookies, setLoggedIn } = useContext(CustomerContext);
  const [loading, setLoading] = useState(false);

    const HandleSubmit = (event) => {
        setError(null)
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setLoading(true);
            fetch(`${REACT_APP_API_URL}/ValidateLogin?username=${postObj.username}&pin=${encodeURIComponent(postObj.pin)}`)
                .then(res => {
                    if (res.status === 200) {
                        res.json().then(text => {
                          let expirationDate = new Date(text.expirationDate)
                          cookies.set('customer', { Token: text.token }, { path: '/', expires: expirationDate })
                          cookies.set('isAdmin', text.isAdmin, { path: '/', expires: expirationDate })
                          cookies.set('isCustomerSupervisor', text.isCustomerSupervisor, { path: '/', expires: expirationDate })
                          setLoading(false);
                          setLoggedIn(true);
                          navigate('/Dashboard', { replace: true })
                        })
                    } else if (res.status === 400 || res.status === 404) {
                        setLoading(false);
                        setError('Invalid PIN, please try again or contact the system administrator')
                    } else if(!res.ok){
                        throw new Error(res)
                    }
                })
                .catch(err => {
                    console.error(err)
                    setError(err.text)
                    setLoading(false)
                })
        }
    }

    const HandleChange = (e, fieldName) => {
        setError(null)
        postObj[fieldName] = e.target.value;
        setPostObj(postObj)
    }
  return (
    <div className="login-card-wrapper">
            <Card className='login-card'>
                <Card.Header><h3>Login</h3></Card.Header>
                <Card.Body>
                    <Form onSubmit={HandleSubmit}>
                        <Form.Group>
                            <Form.Label>A PIN was sent from notice@subroclaims.com to the email associated with your account. Please input the code in the box below.</Form.Label>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>PIN<span>*</span></Form.Label>
                            <Form.Control onChange={(e) => HandleChange(e, 'pin')} placeholder="PIN" required />

                            <Form.Control.Feedback type="invalid">
                                Please provide a Password.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button type="submit" className="margin-top">
                            {loading?
                            <Spinner animation='border' size="sm" />
                            :'Submit'}
                        </Button>
                        {error && <Alert className="margin-top" variant="warning">{error}</Alert>}
                    </Form>
                </Card.Body>
            </Card>
        </div>
  )
}

export default TwoFactorAuth