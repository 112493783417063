import React from 'react'
import { AgGridReact } from 'ag-grid-react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
// import AccountDetailsPopup from './AccountDetailsPopup/AccountDetailsPopup'
import UsersDetailsPopup from './UsersDetailsPopup'

function UserListView({
    openAccounts,
    defaultColDef,
    columnDefs,
    onGridReady,
    onBtnExport,
    hundelAddUesr,
    HandleSubmit,
    onCellClicked,
    openDetail,
    setOpenDetail,
    HandleChangeData,
    debtorID,
    onSubmit
}) {
   

    return (
        <>
            {openDetail && <UsersDetailsPopup show={openDetail} setShow={setOpenDetail} data={debtorID} onSubmit={onSubmit} />}
            <Row>
                <Col className='page-header-wrapper'>
                    <h3 className='page-header'>User List</h3>
                </Col>
                <Col className='page-header-wrapper'>
                   
                </Col>
                <Col className='export-button'>
                    <Button variant='secondary' onClick={hundelAddUesr}>Add Users</Button>
                </Col>
                <Col className='export-button'>
                    <Button variant='secondary' onClick={onBtnExport}>Download CSV</Button>
                </Col>
            </Row>
            <div className="ag-theme-alpine grid" >
                <AgGridReact
                    rowData={openAccounts}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    onCellClicked={onCellClicked}
                />
            </div>
        </>
    )
}

export default UserListView