import React from 'react'
import {Button, Row, Col} from 'react-bootstrap'
import {AgGridReact} from 'ag-grid-react'
import PopupInfo from './PopupInfo/PopupInfo'

function GetPaymentPerformance({ onGridReady, setInfoPopupOpen, infoPopupOpen, onBtnExport, rowData, columnDefs, defaultColDef, onComponentStateChanged }) {
  return (
    <>
    {infoPopupOpen&&<PopupInfo show={infoPopupOpen} setShow={setInfoPopupOpen} />}
    <Row>
      <Col className='page-header-wrapper'>
        <h3 className='page-header'>Payment Performance</h3>
      </Col>
    <Col className='export-button'>
        <Button variant='secondary' onClick={onBtnExport}>Download CSV</Button>
        <Button onClick={(e)=>setInfoPopupOpen(true)} style={{marginLeft:'.5rem'}}>?</Button>
    </Col>
    </Row>
    <div className="ag-theme-alpine grid" >
        <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onComponentStateChanged={onComponentStateChanged}
            tooltipShowDelay={0}
            onGridReady={onGridReady}
            // Holding off on the detail until it's more fleshed out
            // onCellClicked={onCellClicked}
        />
    </div>
</>
  )
}

export default GetPaymentPerformance