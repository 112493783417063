import React from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Button, Row, Col } from 'react-bootstrap'

function GetFinancials({ onFilterChanged, pinnedBottomRowData, onGridReady, onBtnExport, rowData, columnDefs, defaultColDef, onComponentStateChanged }) {
  return (
    <>
      <Row>
        <Col className='page-header-wrapper'>
          <h3 className='page-header'>Project Summary</h3>
        </Col>
        <Col className='export-button'>
          <Button variant='secondary' onClick={onBtnExport}>Download CSV</Button>
        </Col>
      </Row>
      <div className="ag-theme-alpine grid" >
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onComponentStateChanged={onComponentStateChanged}
          pinnedBottomRowData={pinnedBottomRowData}
          onFilterChanged={onFilterChanged}
          onGridReady={onGridReady}
        />
      </div>
    </>
  )
}

export default GetFinancials