import React, { useContext, useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { CustomerContext } from '../CustomerContext/CustomerContext';
import { Button, Row, Col } from 'react-bootstrap';
import useFetchGet from '../Fetch/useFetchGet'

function PaymentHistory() {
    const [gridApi, setGridApi] = useState();
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState();
    const [defaultColDef] = useState({
        resizable: true,
        sortable: true,
        filter: true
    })
    const dollarUSLocale = Intl.NumberFormat('en-US', {
        style: "currency",
        currency: "USD",
    });
    const [columnDefs] = useState([
        { headerName: 'Client Name', field: 'clientname' },
        // {headerName:'Client ID',field:'clientID'},
        { headerName: 'Project', field: 'projectID' },
        { headerName: 'Year Paid', field: 'yearpaid', filter:"agNumberColumnFilter",
    width: 130},
        { headerName: 'Month Paid', field: 'monthpaid', filter:"agNumberColumnFilter",
        width: 145 },
        { headerName: 'Payments', field: 'payments1', filter:"agNumberColumnFilter",
        width: 130, valueFormatter: (data) => dollarUSLocale.format(data.value) },
        // {headerName:'Payments',field:'payments1'},
        { headerName: 'SC Fee', field: 'fee1', filter:"agNumberColumnFilter",
        width: 130, valueFormatter: (data) => dollarUSLocale.format(data.value) },
        { headerName: 'Total Files', field: 'totalfiles1', filter:"agNumberColumnFilter",
        width:130, valueFormatter: (data) => data.value },
        // {headerName:'Principle',field:'principle1', valueFormatter: (data)=> dollarUSLocale.format(data.value)},
        // {headerName:'Agency Fee',field:'agencyFee1', valueFormatter: (data)=> dollarUSLocale.format(data.value)}
    ])
    const { cookies } = useContext(CustomerContext)
    const { REACT_APP_API_URL } = process.env;
    const { data: rowDataVal } = useFetchGet(`${REACT_APP_API_URL}/GetPaymentHistoryReport?token=${cookies.get('customer').Token}`, cookies)
    const [rowData, setRowdata] = useState(null);
    useEffect(() => {
        if (rowDataVal) {
            setRowdata(rowDataVal.filter((item)=>item!=null))
            if (rowData) {
                setPinnedBottomRowData([{
                    payments1: `${rowData.reduce((last, current) => last + current && current.payments1 != null && current.payments1 ? current.payments1 : 0, 0).toFixed(2)}`,
                    fee1: `${rowData.reduce((last, current) => last + current.fee1 ? current.fee1 : 0, 0).toFixed(2)}`,
                    totalfiles1: `${rowData.reduce((last, current) => last + current.totalfiles1, 0)}`,
    
                }])
            }
            
        }
        // eslint-disable-next-line
    }, [rowDataVal])

    const onBtnExport = () => {
        gridApi.exportDataAsCsv();
    }

    const onComponentStateChanged = (params) => {
        setGridApi(params.api)
        // params.api.autoSizeAllColumns(false);
    }

    const onGridReady = (params) => {
        params.api.sizeColumnsToFit();
    }

    const onFilterChanged = (e) => {
        let data = [];
        gridApi.forEachNodeAfterFilter(node => {
            data.push(node.data)
        })
        setPinnedBottomRowData([{
            totalfiles1: `${data.reduce((last, current) => last + current.totalfiles1, 0)}`,
            payments1: `${data.reduce((last, current) => last + current.payments1, 0).toFixed(2)}`,
            fee1: `${data.reduce((last, current) => last + current.fee1, 0).toFixed(2)}`
        }])
    }

    return (
        <>
            <Row>
                <Col className='page-header-wrapper'>
                    <h3 className='page-header'>Payment History</h3>
                </Col>
                <Col className='export-button'>
                    <Button variant='secondary' onClick={onBtnExport}>Download CSV</Button>
                </Col>
            </Row>
            <div className="ag-theme-alpine grid" >
                <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    onComponentStateChanged={onComponentStateChanged}
                    onGridReady={onGridReady}
                    pinnedBottomRowData={pinnedBottomRowData}
                    onFilterChanged={onFilterChanged}
                />
            </div>
        </>
    )
}

export default PaymentHistory;