import React, { useContext, useState } from 'react'
import GetPaymentPerformance from './GetPaymentPerformance'
import useFetchGet from '../Fetch/useFetchGet'
import { CustomerContext } from '../CustomerContext/CustomerContext'
import { useNavigate } from 'react-router-dom'

function GetPaymentPerformanceLogic() {
    const { cookies } = useContext(CustomerContext)
    const [infoPopupOpen, setInfoPopupOpen] = useState(false);
    const { REACT_APP_API_URL } = process.env;
    const { data } = useFetchGet(`${REACT_APP_API_URL}/GetPaymentPerformance?token=${cookies.get('customer').Token}`, cookies)
    const [gridApi, setGridApi] = useState();
    const navigate = useNavigate();
    const [defaultColDef] = useState({
        resizable: true,
        sortable: true,
        filter: true
    })
    const dollarUSLocale = Intl.NumberFormat('en-US', {
        style: "currency",
        currency: "USD",
    });
    const [columnDefs] = useState([
        { headerName: 'Client Name', field: 'clientName' },
        // {headerName:'Client ID',field:'clientId'},
        {
            headerName: 'Year', field: 'year', filter:"agNumberColumnFilter",
            width: 40,
            minWidth: 40,
            maxWidth: 119
        },
        {
            headerName: 'Month', field: 'month',
            filter:"agNumberColumnFilter",
            width: 40,
            minWidth: 40,
            maxWidth: 105
        },
        { headerName: 'Project', field: 'projectID' },
        {
            headerName: 'Principle', field: 'prinbal', filter:"agNumberColumnFilter",
            width: 40,
            minWidth: 40,
            maxWidth: 120,
            valueFormatter: (data) => dollarUSLocale.format(data.value)
        },
        {
            headerName: 'Month0', field: 'month0', headerTooltip: 'This represents the current month.'
            , filter:"agNumberColumnFilter",
            width: 40,
            minWidth: 40,
            maxWidth: 105,
            valueFormatter: (data) => (data.value ? `${data.value}%` : '0%')
        },
        {
            headerName: 'Month 1', field: 'month1', filter:"agNumberColumnFilter",
            width: 40,
            minWidth: 40,
            maxWidth: 105, valueFormatter: (data) => (data.value ? `${data.value}%` : '0%')
        },
        {
            headerName: 'Month 2', field: 'month2', filter:"agNumberColumnFilter",
            width: 40,
            minWidth: 40,
            maxWidth: 105, valueFormatter: (data) => (data.value ? `${data.value}%` : '0%')
        },
        {
            headerName: 'Month 3', field: 'month3', filter:"agNumberColumnFilter",
            width: 40,
            minWidth: 40,
            maxWidth: 105, valueFormatter: (data) => (data.value ? `${data.value}%` : '0%')
        },
        {
            headerName: 'Month 4', field: 'month4', filter:"agNumberColumnFilter",
            width: 40,
            minWidth: 40,
            maxWidth: 105, valueFormatter: (data) => (data.value ? `${data.value}%` : '0%')
        },
        {
            headerName: 'Month 5', field: 'month5', filter:"agNumberColumnFilter",
            width: 40,
            minWidth: 40,
            maxWidth: 105, valueFormatter: (data) => (data.value ? `${data.value}%` : '0%')
        },
        {
            headerName: 'Month 6', field: 'month6', filter:"agNumberColumnFilter",
            width: 40,
            minWidth: 40,
            maxWidth: 105, valueFormatter: (data) => (data.value ? `${data.value}%` : '0%')
        },
        {
            headerName: 'Month 7', field: 'month7', filter:"agNumberColumnFilter",
            width: 40,
            minWidth: 40,
            maxWidth: 105, valueFormatter: (data) => (data.value ? `${data.value}%` : '0%')
        },
        {
            headerName: 'Month 8', field: 'month8', filter:"agNumberColumnFilter",
            width: 40,
            minWidth: 40,
            maxWidth: 105, valueFormatter: (data) => (data.value ? `${data.value}%` : '0%')
        },
        {
            headerName: 'Month 9', field: 'month9', filter:"agNumberColumnFilter",
            width: 40,
            minWidth: 40,
            maxWidth: 105, valueFormatter: (data) => (data.value ? `${data.value}%` : '0%')
        },
        {
            headerName: 'Month 10', field: 'month10', filter:"agNumberColumnFilter",
            width: 40,
            minWidth: 40,
            maxWidth: 115, valueFormatter: (data) => (data.value ? `${data.value}%` : '0%')
        },
        {
            headerName: 'Month 11', field: 'month11', filter:"agNumberColumnFilter",
            width: 40,
            minWidth: 40,
            maxWidth: 115, valueFormatter: (data) => (data.value ? `${data.value}%` : '0%')
        },
        {
            headerName: 'Month 12', field: 'month12', filter:"agNumberColumnFilter",
            width: 40,
            minWidth: 40,
            maxWidth: 115, valueFormatter: (data) => (data.value ? `${data.value}%` : '0%')
        },
        {
            headerName: 'Past Month 12', field: 'pastMonth12', filter:"agNumberColumnFilter",
            width: 40,
            minWidth: 40,
            maxWidth: 145, valueFormatter: (data) => (data.value ? `${data.value}%` : '0%')
        },
    ])

    // useEffect(()=>{
    //     if(data){
    //         setPinnedBottomRowData([{
    //             prinbal: `${data.reduce((last,current)=>last+current.prinbal,0).toFixed(2)}`,
    //             month0: `${data.reduce((last,current)=>last+current.month0,0).toFixed(2)}`,
    //             month1: `${data.reduce((last,current)=>last+current.month1,0).toFixed(2)}`,
    //             month2: `${data.reduce((last,current)=>last+current.month2,0).toFixed(2)}`,
    //             month3: `${data.reduce((last,current)=>last+current.month3,0).toFixed(2)}`,
    //             month4: `${data.reduce((last,current)=>last+current.month4,0).toFixed(2)}`,
    //             month5: `${data.reduce((last,current)=>last+current.month5,0).toFixed(2)}`,
    //             month6: `${data.reduce((last,current)=>last+current.month6,0).toFixed(2)}`,
    //             month7: `${data.reduce((last,current)=>last+current.month7,0).toFixed(2)}`,
    //             month8: `${data.reduce((last,current)=>last+current.month8,0).toFixed(2)}`,
    //             month9: `${data.reduce((last,current)=>last+current.month9,0).toFixed(2)}`,
    //             month10: `${data.reduce((last,current)=>last+current.month10,0).toFixed(2)}`,
    //             month11: `${data.reduce((last,current)=>last+current.month11,0).toFixed(2)}`,
    //             month12: `${data.reduce((last,current)=>last+current.month12,0).toFixed(2)}`,
    //             pastMonth12: `${data.reduce((last,current)=>last+current.pastMonth12,0).toFixed(2)}`,
    //         }])
    //     }
    // },[data])

    const onBtnExport = () => {
        gridApi.exportDataAsCsv();
    }

    const onComponentStateChanged = (params) => {
        setGridApi(params.api)
        params.columnApi.autoSizeAllColumns()
    }

    const onCellClicked = (e) => {
        navigate('/PaymentPerformanceDetail', { state: { month: e.data.month, year: e.data.year } })
    }

    // const onFilterChanged = (e) => { 
    //     let data = [];
    //     gridApi.forEachNodeAfterFilter(node=>{
    //         data.push(node.data)
    //     })
    //     setPinnedBottomRowData([{
    //         prinbal: `${data.reduce((last,current)=>last+current.prinbal,0).toFixed(2)}`,
    //         month0: `${data.reduce((last,current)=>last+current.month0,0).toFixed(2)}`,
    //         month1: `${data.reduce((last,current)=>last+current.month1,0).toFixed(2)}`,
    //         month2: `${data.reduce((last,current)=>last+current.month2,0).toFixed(2)}`,
    //         month3: `${data.reduce((last,current)=>last+current.month3,0).toFixed(2)}`,
    //         month4: `${data.reduce((last,current)=>last+current.month4,0).toFixed(2)}`,
    //         month5: `${data.reduce((last,current)=>last+current.month5,0).toFixed(2)}`,
    //         month6: `${data.reduce((last,current)=>last+current.month6,0).toFixed(2)}`,
    //         month7: `${data.reduce((last,current)=>last+current.month7,0).toFixed(2)}`,
    //         month8: `${data.reduce((last,current)=>last+current.month8,0).toFixed(2)}`,
    //         month9: `${data.reduce((last,current)=>last+current.month9,0).toFixed(2)}`,
    //         month10: `${data.reduce((last,current)=>last+current.month10,0).toFixed(2)}`,
    //         month11: `${data.reduce((last,current)=>last+current.month11,0).toFixed(2)}`,
    //         month12: `${data.reduce((last,current)=>last+current.month12,0).toFixed(2)}`,
    //         pastMonth12: `${data.reduce((last,current)=>last+current.pastMonth12,0).toFixed(2)}`,
    //     }])
    //  }

    const onGridReady = (params) => {
        params.columnApi.autoSizeAllColumns(false);
    }

    return (
        <GetPaymentPerformance
            rowData={data}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onComponentStateChanged={onComponentStateChanged}
            onBtnExport={onBtnExport}
            onCellClicked={onCellClicked}
            infoPopupOpen={infoPopupOpen}
            setInfoPopupOpen={setInfoPopupOpen}
            onGridReady={onGridReady}
        ></GetPaymentPerformance>
    )
}

export default GetPaymentPerformanceLogic