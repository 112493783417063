import React, { useState } from 'react'
import PasswordReset from './PasswordReset'
import { useNavigate } from "react-router-dom";

function PasswordResetLogic({ show, setShow, email, setMessage }) {
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState();
    const [loading, setLoading] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState();
    const [pin, setPin] = useState();
    const [error, setError] = useState();
    const { REACT_APP_API_URL } = process.env;
    const [validated, setValidated] = useState(false);

    const HandleSubmit = (e) => {
        e.preventDefault()
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.stopPropagation();
        } else {
            if (newPassword !== confirmPassword) {
                setError('Passwords do not match')
            } else {
                setLoading(true);
                fetch(`${REACT_APP_API_URL}/reset?username=${email}&password=${encodeURIComponent(newPassword)}&pin=${pin}`, {
                    method: 'PATCH',
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then(res => {
                        if (res.status === 200) {
                            setMessage('Reset successful')
                            setShow(false)
                            setLoading(false)
                            navigate('/', { replace: true })

                        }
                        setError('Issue resetting password, please try again')
                        setLoading(false)
                    })
            }
        }
        setValidated(true);
    }

    return (
        <PasswordReset
            setNewPassword={setNewPassword}
            setConfirmPassword={setConfirmPassword}
            setPin={setPin}
            HandleSubmit={HandleSubmit}
            error={error}
            setError={setError}
            show={show}
            setShow={setShow}
            loading={loading}
            confirmPassword={confirmPassword}
            newPassword={newPassword}
            validated={validated}
        ></PasswordReset>
    )
}

export default PasswordResetLogic