import React from 'react'
import { Modal, Col, Row, Button, Form } from 'react-bootstrap'

function PopupInfo({ setShow, show }) {
  return (
    <Modal show={show} onHide={setShow}>
        <Modal.Header closeButton>
        <h5>Project Definitions</h5>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col className='content-info-bold'>
                    <p>Arbitration:</p>
                </Col>
                <Col>
                    <p>Direct Placed Arbitration</p>
                </Col>
            </Row>
            <Row>
                <Col className='content-info-bold'>
                    <p>Contribution UIM:</p>
                </Col>
                <Col>
                    <p>PD Excess Limits</p>
                </Col>
            </Row>
            <Row>
                <Col className='content-info-bold'>
                    <p>Double Dip:</p>
                </Col>
                <Col>
                    <p>Insured Policy Holder Paid 2x</p>
                </Col>
            </Row>
            <Row>
                <Col className='content-info-bold'>
                    <p>Pre-Lit:</p>
                </Col>
                <Col>
                    <p>Business Entities</p>
                </Col>
            </Row>
            <Row>
                <Col className='content-info-bold'>
                    <p>Subrogation:</p>
                </Col>
                <Col>
                    <p>Direct Placed TPC</p>
                </Col>
            </Row>
            <Row>
                <Col className='content-info-bold'>
                    <p>SubroGovt:</p>
                </Col>
                <Col>
                    <p>Government and City Entities</p>
                </Col>
            </Row>
            <Row>
                <Col className='content-info-bold'>
                    <p>UM:</p>
                </Col>
                <Col>
                    <p>Uninsured Motorists</p>
                </Col>
            </Row>
            <Row>
                <Col className='content-info-bold'>
                    <p>Property:</p>
                </Col>
                <Col>
                    <p>Property Subrogation</p>
                </Col>
            </Row>
                <Row>
                    <h6 style={{textAlign:'center',marginTop:'1rem'}}>Month Columns:</h6>
                    <Form.Control as='textarea' style={{height:'20vh'}} value='Month 0 represents the current month. Month1 represents the sequential month based on the list date of the claim. Values are accumulative percentage of amount recovered based on amount due. Consistant payments can be attributed to payment plans. Month 12+ represents all other months up to current date.' />
                </Row>
        </Modal.Body>
        <Modal.Footer>
            <Button variant='secondary'>Close</Button>
        </Modal.Footer>
    </Modal>
  )
}

export default PopupInfo
