import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { CustomerContext } from '../CustomerContext/CustomerContext'

function PrivateRoute({ children }) {
    const {
        cookies
    } = useContext(CustomerContext)
    const authed = (!(cookies.get('customer')==null))

  return authed === true
  ? children
  : <Navigate to='/login' replace />
}

export default PrivateRoute