import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
 
function Loss({ accountDetails }) {
    const [date, setDate] = useState(accountDetails.lossDt?accountDetails.lossDt:'');
    const [time, setTime] = useState(accountDetails.timeOfLoss?accountDetails.timeOfLoss:'');
    // const [city, setCity] = useState(accountDetails.lossCity?accountDetails.lossCity:'');
    const [state, setState] = useState(accountDetails.stateOfLoss?accountDetails.stateOfLoss:'');
    const [policeReport, setPoliceReport] = useState(accountDetails.policeReport?accountDetails.policeReport:false);
    const [description, setDescription] = useState(accountDetails.lossDescriptions?accountDetails.lossDescriptions:'')
    const [comments, setComments] = useState(accountDetails.comment?accountDetails.comment:'');

  return (
    <Card className='padding'>
    <Row>
        <Form.Group as={Col}>
            <Form.Label>Date</Form.Label>
            <Form.Control value={date} onChange={(e)=>setDate(e.target.value)} readOnly />
        </Form.Group>
        <Form.Group as={Col}>
            <Form.Label>Time</Form.Label>
            <Form.Control  readOnly value={time} onChange={(e)=>setTime(e.target.value)} />
        </Form.Group>
    </Row>
    <Row>
        {/* <Form.Group as={Col}>
            <Form.Label>City</Form.Label>
            <Form.Control  readOnly value={city} onChange={(e)=>setCity(e.target.value)} />
        </Form.Group> */}
        <Form.Group as={Col}>
            <Form.Label>State</Form.Label>
            <Form.Control  disabled value={state} onChange={(e)=>setState(e.target.value)} />
        </Form.Group>
    </Row>
    <Row>
        <Form.Group as={Col}>
            <Form.Label>Police Report</Form.Label>
            <Form.Check  disabled value={policeReport} onChange={(e)=>setPoliceReport(e.target.value)} />
        </Form.Group>
    </Row>
    <Row>
        <Form.Group as={Col}>
            <Form.Label>Description</Form.Label>
            <Form.Control  readOnly as={'textarea'} value={description} onChange={(e)=>setDescription(e.target.value)} />
        </Form.Group>
    </Row>
    <Row>
        <Form.Group as={Col}>
            <Form.Label>Comment</Form.Label>
            <Form.Control  readOnly as={'textarea'} value={comments} onChange={(e)=>setComments(e.target.value)} />
        </Form.Group>
    </Row>
</Card>
  )
}

export default Loss