import React from 'react';
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './index.css'
import Layout from "./Components/Layout/Layout";
import LoginLogic from "./Components/Login/LoginLogic";
import ProjectStatus from "./Components/ProjectStatus/ProjectStatus";
import { CustomerProvider } from "./Components/CustomerContext/CustomerContext";
import PrivateRoute from "./Components/PrivateRoute/PrivateRoute";
import GetFinancialsLogic from "./Components/GetFinancials/GetFinancialsLogic";
import GetPaymentPerformanceLogic from "./Components/GetPaymentPerformance/GetPaymentPerformanceLogic";
import GetPaymentPerformanceDetailLogic from "./Components/GetPaymentPerformance/GetPaymentPerformanceDetail/GetPaymentPerformanceDetailLogic";
import OpenAccounts from "./Components/AllOpenClaims/OpenAccounts";
import ProjectUIM from './Components/ProjectUIM/ProjectUIM';
import UserList from './Components/AllOpenClaims/UserDetails/UserList';
import PaymentHistory from './Components/PaymentHistory/PaymentHistory';
import ForgotPasswordLogic from './Components/ForgotPassword/ForgotPasswordLogic';
import TwoFactorAuth from './Components/Login/TwoFactorAuth';
// import your route components too

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

root.render(
  <CustomerProvider>
    <Layout>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginLogic />} />
          <Route path="/login" element={<LoginLogic />} />
          <Route path="/TwoFactorAuth" element={<TwoFactorAuth />} />
          <Route path="/crsweb" element={<LoginLogic />} />
          <Route path="/forgotPassword" element={<ForgotPasswordLogic />} />
          <Route path="/Dashboard" element={<PrivateRoute><OpenAccounts/></PrivateRoute>} />
          <Route path="/userlist" element={<PrivateRoute><UserList/></PrivateRoute>} />
          <Route path="/ProjectStatus" element={<PrivateRoute><ProjectStatus /></PrivateRoute>} />
          <Route path="/ProjectUIM" element={<PrivateRoute><ProjectUIM /></PrivateRoute>} />
          <Route path="/PaymentHistory" element={<PrivateRoute><PaymentHistory /></PrivateRoute>} />
          <Route path="/ProjectSummary" element={<PrivateRoute><GetFinancialsLogic/></PrivateRoute>} />
          <Route path="/PaymentPerformance" element={<PrivateRoute><GetPaymentPerformanceLogic/></PrivateRoute>} />
          <Route path="/PaymentPerformanceDetail" element={<PrivateRoute><GetPaymentPerformanceDetailLogic /></PrivateRoute>} />
        </Routes>
      </BrowserRouter>
    </Layout>
  </CustomerProvider>
);