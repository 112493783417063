import React, { useContext, useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { CustomerContext } from '../CustomerContext/CustomerContext';
import { Button, Row, Col } from 'react-bootstrap';
import useFetchGet from '../Fetch/useFetchGet'

function ProjectUIM() {
    const [gridApi, setGridApi] = useState();
    const [pinnedBottomRowData, setPinnedBottomRowData] = useState();
    const [defaultColDef] = useState({
        resizable: true,
        sortable: true,
        filter: true
    })
    const dollarUSLocale = Intl.NumberFormat('en-US',{
        style: "currency",
        currency: "USD",
    });
    const [columnDefs] = useState([
        {headerName:'Client Name',field:'clientName'},
        // {headerName:'Client ID',field:'clientID'},
        {headerName:'Year',field:'listYear'},
        {headerName:'Month',field:'listMonth'},
        {headerName:'Project ID',field:'projectID'},
        {headerName:'Account Status',field:'acctStatus'},
        {headerName:'Total Files',field:'totalFiles1'},
        {headerName:'Principle',field:'principle1', valueFormatter: (data)=> dollarUSLocale.format(data.value)},
        {headerName:'Payments',field:'payments1', valueFormatter: (data)=> dollarUSLocale.format(data.value)},
        {headerName:'Agency Fee',field:'agencyFee1', valueFormatter: (data)=> dollarUSLocale.format(data.value)}
    ])
    const {cookies} = useContext(CustomerContext)
    const {REACT_APP_API_URL} = process.env;
    const {data: rowData} = useFetchGet(`${REACT_APP_API_URL}/GetClientFinancialsUIM?token=${cookies.get('customer').Token}`,cookies)

    useEffect(()=>{
        if(rowData){
            setPinnedBottomRowData([{
                totalFiles1: `${rowData.reduce((last,current)=>last+current.totalFiles1,0).toFixed(2)}`,
                principle1: `${rowData.reduce((last,current)=>last+current.principle1,0).toFixed(2)}`,
                payments1: `${rowData.reduce((last,current)=>last+current.payments1,0).toFixed(2)}`,
                agencyFee1: `${rowData.reduce((last,current)=>last+current.agencyFee1,0).toFixed(2)}`
            }])
        }
  // eslint-disable-next-line
    },[rowData])

    const onBtnExport = () => { 
        gridApi.exportDataAsCsv();
     }

    const onComponentStateChanged = (params) => { 
        setGridApi(params.api)
        params.columnApi.autoSizeAllColumns(false);
     }

    const onGridReady = (params) => {
        params.columnApi.autoSizeAllColumns(false);
    }

    const onFilterChanged = (e) => { 
        let data = [];
        gridApi.forEachNodeAfterFilter(node=>{
            data.push(node.data)
        })
        setPinnedBottomRowData([{
            totalFiles1: `${data.reduce((last,current)=>last+current.totalFiles1,0).toFixed(2)}`,
            principle1: `${data.reduce((last,current)=>last+current.principle1,0).toFixed(2)}`,
            payments1: `${data.reduce((last,current)=>last+current.payments1,0).toFixed(2)}`,
            agencyFee1: `${data.reduce((last,current)=>last+current.agencyFee1,0).toFixed(2)}`
        }])
     }

  return (
    <>
    <Row>
      <Col className='page-header-wrapper'>
        <h3 className='page-header'>Project UIM</h3>
      </Col>
      <Col className='export-button'>
        <Button variant='secondary' onClick={onBtnExport}>Download CSV</Button>
      </Col>
    </Row>
        <div className="ag-theme-alpine grid" >
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                onComponentStateChanged={onComponentStateChanged}
                onGridReady={onGridReady}
                pinnedBottomRowData={pinnedBottomRowData}
                onFilterChanged={onFilterChanged}
                />
        </div>
    </>
  )
}

export default ProjectUIM;