import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import General from './AccordionItems/General'
// import AdverseCarrier from './AccordionItems/AdverseCarrier'
import Loss from './AccordionItems/Loss'
import Financial from './AccordionItems/Financial'
import Insured from './AccordionItems/Insured'
import Claimant from './AccordionItems/Claimant'
import RegisteredOwner from './AccordionItems/RegisteredOwner'
import ClaimantVehicle from './AccordionItems/ClaimantVehicle'
import Comments from './AccordionItems/Comments'
import PaymentHistory from './AccordionItems/PaymentHistory'




function AccountDetailsPopupView({ show, setShow, accountDetails, activities, paymentHistory ,statusCode, accountBalance, financial}) {
    return (
        <Modal show={show} onHide={setShow}  dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title">
            <Modal.Header closeButton>Account Details  {accountDetails && accountDetails.clientRefNo ? accountDetails.clientRefNo : ''}</Modal.Header>
            <Modal.Body className='accountDetaislsBody'>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>General Information</Accordion.Header>
                        <Accordion.Body>
                            {accountDetails ?
                                <General accountDetails={accountDetails} statusCode={statusCode} />
                                :
                                <Spinner animation='border' />
                            }
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="8">
                        <Accordion.Header>Notes</Accordion.Header>
                        <Accordion.Body>
                            {activities ?
                                <Comments activities={activities} debtorID={accountDetails.debtorID} />
                                :
                                <Spinner animation='border' />
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                        <Accordion.Header>Payment History</Accordion.Header>
                        <Accordion.Body>
                            {paymentHistory !== '' ?
                                <PaymentHistory paymentHistory={paymentHistory ? paymentHistory : []} />
                                :
                                <div>
                                    <span>No Data Found</span>
                                </div>
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="1">
                        <Accordion.Header>Adverse Carrier</Accordion.Header>
                        <Accordion.Body>
                            {adverseCarrier ?
                                <AdverseCarrier adverseCarrier={adverseCarrier} />
                                :
                                <Spinner animation='border' />
                            }
                        </Accordion.Body>
                    </Accordion.Item> */}
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Loss</Accordion.Header>
                        <Accordion.Body>
                            {accountDetails ?
                                <Loss accountDetails={accountDetails} />
                                :
                                <Spinner animation='border' />
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Financial</Accordion.Header>
                        <Accordion.Body>
                            {accountDetails ?
                                <Financial financial={financial} />
                                :
                                <Spinner animation='border' />
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>Insured</Accordion.Header>
                        <Accordion.Body>
                            {accountDetails ?
                                <Insured accountDetails={accountDetails} />
                                :
                                <Spinner animation='border' />
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>Claimant</Accordion.Header>
                        <Accordion.Body>
                            {
                                accountDetails ?
                                    <Claimant accountDetails={accountDetails} />
                                    :
                                    <Spinner animation='border' />
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>Registered Owner</Accordion.Header>
                        <Accordion.Body>
                            {accountDetails ?
                                <RegisteredOwner accountDetails={accountDetails} />
                                :
                                <Spinner animation='border' />
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header>Claimant Vehicle</Accordion.Header>
                        <Accordion.Body>
                            {accountDetails ?
                                <ClaimantVehicle accountDetails={accountDetails} />
                                :
                                <Spinner animation='border' />
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Modal.Body>
            <Modal.Footer>
                <Button className='full-width' variant='secondary' onClick={() => setShow(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AccountDetailsPopupView