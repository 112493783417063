import { useEffect, useState } from 'react'

function useFetchGet(url, cookies, cookieName, refreshTime) {
    const [data, setData] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [url])

    useEffect(() => {
        if (refreshTime) {
            const timer = setTimeout(() => {
                fetchData()
            }, refreshTime)
            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line
    })

    const fetchData = () => {
        fetch(url)
            .then(res => {
                if (res.status === 200) {
                    return res.json()
                } else if (res.status === 401) {
                    cookies.remove('customer', { path: '/' })
                    cookies.remove('isAdmin', { path: '/' })
                    cookies.remove('isCustomerSupervisor', { path: '/' })
                    
                    window.location.reload();
                } else {
                    throw Error(res.statusText)
                }
            })
            .then(data => {
                setIsLoaded(true)
                setData(data)
            })
            .catch(error => {
                setError(error)
            })
    }

    return { error, isLoaded, data }
}

export default useFetchGet