import React, { useContext, useEffect, useState } from 'react'
import { CustomerContext } from '../../CustomerContext/CustomerContext'
import useFetchGet from '../../Fetch/useFetchGet';
import UserListView from './UserListView'
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

function UserList() {
    const [gridApi, setGridApi] = useState();
    //const [updateData, satUpdateData] = useState();
    const [filterData, setFilterData] = useState(null);
    const [openDetail, setOpenDetail] = useState(false);
    const [debtorID, setDebtorID] = useState({
        active: true, emailAddr1: null, emailAddr2: null, givenName: null, isAdmin: false, groupRegions: false, lastLogon: "2000-01-01T00:00:00", password: null, password2: null, phoneNumber1: null, phoneNumber2: null, salt: 0, surname: null, userName: null
    });
    const [toastShow, setToastShow] = useState(false)

    const { cookies } = useContext(CustomerContext)
    const { REACT_APP_API_URL } = process.env;
    const [openAccounts, setOpenAccounts] = useState(null);


    let { data: openAccount } = useFetchGet(`${REACT_APP_API_URL}/GetUsers?Token=${cookies.get('customer').Token}&activeOnly=true`)

    useEffect(() => {
        const fetchData = () => {
            setOpenAccounts(openAccount);
        };
        if (openAccount && openAccount.length > 0) {
            fetchData();
        } else { return }
    }, [openAccount]);

    const fetchData = () => {
        fetch(`${REACT_APP_API_URL}/GetUsers?Token=${cookies.get('customer').Token}&activeOnly=true`)
            .then(res => {
                if (res.status === 200) {
                    return res.json()
                } else if (res.status === 401) {
                    cookies.remove('customer', { path: '/' })
                    cookies.remove('isAdmin', { path: '/' })
                    cookies.remove('isCustomerSupervisor', { path: '/' })
                    window.location.reload();
                } else {
                    throw Error(res.statusText)
                }
            })
            .then(data => {
                //setIsLoaded(true)
                setOpenAccounts(data);

                //setData(data)
            })
            .catch(error => {
                // setError(error)
            })
    }
    const [defaultColDef] = useState({
        resizable: true,
        sortable: true,
        filter: true
    }) 
    
    const dateFilterComparator = (filterLocalDateAtMidnight, cellValue)=> {
        // Convert the filter date to a comparable value
        const filterDate = new Date(filterLocalDateAtMidnight);
        const filterValue = filterDate.getTime();

        // Convert the cell value to a comparable value
        const cellDate = new Date(cellValue);
        const cellVal = cellDate.getTime();

        // Compare the cell value with the filter value
        if (cellVal > filterValue) {
            // The cell value is greater than the filter value
            return 1;
        } else if (cellVal < filterValue) {
            // The cell value is less than the filter value
            return -1;
        } else {
            // The cell value is equal to the filter value
            return 0;
        }
    }   
    const customConcatenatedFilter = (filter, value, filterText)=> {
        const concatenatedValue = value;
        return concatenatedValue.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
    }
    const [columnDefs] = useState([
        {
            headerName: 'Name', field: 'surname',
            filter: 'agTextColumnFilter',
            filterParams: {
                textCustomComparator: customConcatenatedFilter,
            },
            valueGetter: function (params) {
                return params.data.surname + ' ' + params.data.givenName;
            }
        },
        { headerName: 'User Name', field: 'userName' },
        { headerName: 'Email', field: 'emailAddr1' },
        { headerName: 'Phone No', field: 'phoneNumber1' },
        { headerName: 'Active', field: 'active', width:120 },
        { headerName: 'Last Login', field: 'lastLogon', valueFormatter: (param) => transformDate(param),  filter: 'agDateColumnFilter', width:178,
        filterParams: { comparator: dateFilterComparator } }
    ])

    const HandleChangeData = (val) => {
        if (val === '') {
            setFilterData(null);
        }
    }
    const onSubmit = (val) => {
        setToastShow(true)
        setOpenDetail(false);
        fetchData();
    }

    const transformDate = (params) => {
        if (params.value) {
            var date = new Date(params.value);
            return date.toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
            }).split('/').join('-');
        }
    }
    // const concatData = (params) => {
    //     if (params.data && params.data.surname && params.data.givenName) {
    //         return `${params.data.surname} ${params.data.givenName}`
    //     }
    // }

    const onGridReady = (params) => {
        setGridApi(params.api)
        params.columnApi.autoSizeAllColumns();
    }

    const onBtnExport = () => {
        gridApi.exportDataAsCsv();
    }
    const hundelAddUesr = () => {
        setDebtorID({
            active: true, emailAddr1: "", emailAddr2: '', givenName: "", isAdmin: false, isCustomerSupervisor: false, groupRegions: false, lastLogon: "2000-01-01T00:00:00", password: "", password2: "", phoneNumber1: "", phoneNumber2: "", salt: 0, surname: "", userName: ""
        });
        setOpenDetail(true)
    }

    const onCellClicked = (params) => {
        setDebtorID(params.data)
        setOpenDetail(true)
    }

    return (
        <>
            <ToastContainer className="p-3" position={'top-end'}>
                <Toast style={{ backgroundColor: "springgreen", }}
                    className="d-inline-block m-1 "
                    onClose={() => setToastShow(false)} show={toastShow} delay={1000} autohide>
                    <Toast.Body className={'Success'}>
                        User {debtorID && debtorID.userId ? 'Uptdate' : 'Create'}d
                    </Toast.Body>
                </Toast>
            </ToastContainer>

            <UserListView
                openAccounts={filterData !== null ? filterData : openAccounts}
                defaultColDef={defaultColDef}
                columnDefs={columnDefs}
                onGridReady={onGridReady}
                onBtnExport={onBtnExport}
                onCellClicked={onCellClicked}
                openDetail={openDetail}
                setOpenDetail={setOpenDetail}
                debtorID={debtorID}
                HandleChangeData={HandleChangeData}
                onSubmit={onSubmit}
                hundelAddUesr={hundelAddUesr}
            ></UserListView>
        </>
    )
}

export default UserList