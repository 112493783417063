import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
 
function ClaimantVehicle({ accountDetails }) {
  const [makeAndModel, setMakeAndModel] = useState(accountDetails.vehicleMake?accountDetails.vehicleMake:'');
  const [year, setYear] = useState(accountDetails.yr?accountDetails.yr:'');
  const [licensePlate, setLicensePlate] = useState(accountDetails.plateNo?accountDetails.plateNo:'');

  return (
    <Card className='padding'>
    <Row>
        <Form.Group as={Col}>
            <Form.Label>Make/Model</Form.Label>
            <Form.Control readOnly value={makeAndModel} onChange={(e)=>setMakeAndModel(e.target.value)} />
        </Form.Group>
        <Form.Group as={Col}>
            <Form.Label>Model Year</Form.Label>
            <Form.Control readOnly value={year} onChange={(e)=>setYear(e.target.value)} />
        </Form.Group>
        <Form.Group as={Col}>
            <Form.Label>License Plate #</Form.Label>
            <Form.Control readOnly value={licensePlate} onChange={(e)=>setLicensePlate(e.target.value)} />
        </Form.Group>
    </Row>
</Card>
  )
}

export default ClaimantVehicle